import React from "react";

// Components
import SecondaryLanding from "../../../layouts/secondary-landing";
import SEO from "../../../components/seo/seo";
import Breadcrumb from "../../../components/breadcrumb/breadcrumb";
import FaqAccordion from "../../../components/faq/faq-accordion";
import ContactInfo from "../../../components/contact-info/contact-info";

const SpanishRewardsCheckingFAQ = () => {
  const seoData = {
    title: "Beneficios y servicios de cheques de recompensas: preguntas frecuentes",
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        property: "og:title",
        content: "Beneficios y servicios de cheques de recompensas: preguntas frecuentes"
      },
      {
        name: "description",
        property: "og:description",
        content:
          "Compare los beneficios de la cuenta de cheques WaFd Bank Rewards. Acceda a recompensas y servicios como descuentos de salud, asistencia en carretera y ahorro de combustible. Llame al 800-324-9375 para obtener más detalles."
      },
      {
        property: "og:url",
        content:
          "https://www.wafdbank.com/es/banca-personal/preguntas-frecuentes-sobre-los-rewards-de-cuentas-de-cheques"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      }
    ]
  };

  const breadcrumbData = [
    {
      id: 1,
      active: false,
      url: "/es", // There is no /es/banca-personal page. There is a redirect, but we're skipping it here.
      title: "Banca personal"
    },
    {
      id: 2,
      active: true,
      title: "Preguntas frecuentes Rewards de cuentas de Cheques"
    }
  ];

  const faqAccordionData = {
    id: "es-rewards-checking-faq-accordion",
    faqCategoryNames: ["Spanish Rewards Checking"],
    showContactBar: false,
    title: "Preguntas frecuentes"
  };

  return (
    <SecondaryLanding>
      <SEO {...seoData} />
      <Breadcrumb data={breadcrumbData} />
      <section className="container pb-0">
        <h1>Preguntas frecuentes sobre los Rewards de cuentas de Cheques</h1>
        <h3>
          WaFd Bank se complace en extender los Rewards de la cuenta corriente<sup>1</sup> a aquellos con recompensas,
          recompensas premium, intereses o cuentas corrientes selectas.
        </h3>
        <p className="mb-0">
          Si tiene alguna pregunta adicional, llámenos al{" "}
          <a href="tel:800-324-9375" id="c3-tel-link">
            800-324-9375
          </a>
          .
        </p>
      </section>
      <FaqAccordion {...faqAccordionData} />
      <ContactInfo isSpanish={true} />
    </SecondaryLanding>
  );
};

export default SpanishRewardsCheckingFAQ;
